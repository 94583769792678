export default function EthIcon(props) {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="20" cy="20" r="20" fill="#EFF0FB" />
            <path d="M10 19.7138L19.9997 4L29.9994 19.7138L19.9997 25.4279L10 19.7138Z" fill="#7880E7" />
            <path d="M20 4L29.9997 19.7138L20 25.4279V4Z" fill="#5C64C7" />
            <path d="M10 21.8564L19.9997 27.5706L29.9994 21.8564L19.9997 35.4275L10 21.8564Z" fill="#7880E7" />
            <path d="M19.9997 27.5712L29.9994 21.8571L19.9997 35.4281V27.5712ZM10 19.7143L19.9997 15.4287L29.9994 19.7143L19.9997 25.4284L10 19.7143Z" fill="#5C64C7" />
            <path d="M20 15.4287L29.9997 19.7143L20 25.4284V15.4287Z" fill="#2A3192" />
        </svg>
    )
}

